.headerOption,
.headerOption__screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  color: gray;
  cursor: pointer;
}

.headerOption:hover {
  color: black;
}

.headerOption__title {
  font-size: 12px;
  font-weight: 400;
  text-decoration: none ;

}

.headerOption__icon {
  object-fit: contain;
  height: 25px !important;
  width: 25px !important;
}

@media screen and (max-width: 450px) {
  .headerOption__screen {
    display: none;
  }
}

@media screen and (max-width: 710px) {
  .headerOption__screen {
    display: none;
  }
}